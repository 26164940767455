import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';

import '../../styles.css';
import TestimonialSection from '../components/sections/testimonial';
import MainDescription from '../components/sections/heroSection';
import FeaturesSection from '../components/sections/features';
import UltimateWinningsSection from '../components/sections/ultimateGamingExperience';
import FaqSection from '../components/sections/faq';
import { getApkDynamiClick } from '../../utils/downloadLinkUtils';
import CommonPageRenderer from '../components/pageRendererHoc/CommonPageRenderer';
import PageProvider from '../context/page/PageProvider';
import { ANALYTICS_CONSTANTS } from '../constants';
import { logEventsOnLoad } from '../../utils/redirectCheck';
// import ExitPopup from '../components/fields/exitPopup';

const IndexPage = ({ data }) => {
	const [apkPromise, setApkPromise] = useState('');

	useEffect(() => {
		setApkPromise(getApkDynamiClick());
		logEventsOnLoad();
	}, []);

	return (
		<PageProvider data={data} page='homepage' apkPromise={apkPromise}>
			<div className='homepage'>
				{/* <ExitPopup /> */}
				<CommonPageRenderer
					fbEventName={ANALYTICS_CONSTANTS.FB_EVENT_HOME_PAGE}
					pageName={ANALYTICS_CONSTANTS.HOME_PAGE_NAME}>
					<MainDescription />
					<FeaturesSection />
					<TestimonialSection />
					<UltimateWinningsSection />
					<FaqSection />
				</CommonPageRenderer>
			</div>
		</PageProvider>
	);
};

export const query = graphql`
{
	allWpPage(filter: {uri: {eq: "/home/"}}) {
	  edges {
		node {
		  id
		  homepage {
			homepageTestimonials {
			  wontextbackgroundcolor
			  bordercolor
			  background {
				color
				image {
				  localFile {
					childImageSharp {
					  gatsbyImageData
					}
				  }
				}
			  }
			  downloadandroidimage {
				url
				image {
				  alttext
				  imagefile {
					localFile {
					  childImageSharp {
						gatsbyImageData
					  }
					}
				  }
				}
			  }
			  ratingimage {
				alttext
				imagefile {
				  localFile {
					childImageSharp {
					  gatsbyImageData
					}
				  }
				}
			  }
			  downloadiosimage {
				url
				image {
				  alttext
				  imagefile {
					localFile {
					  childImageSharp {
						gatsbyImageData
					  }
					}
				  }
				}
			  }
			  titletext {
				text
				color
			  }    
			  titlesubheading{
				text
				color
		      }
			  winnertitle {
				text
				color
			  }
			  sliderbutton {
				lefticon {
				  color
				  image {
					alttext
					imagefile {
					  localFile {
						childImageSharp {
						  gatsbyImageData
						}
					  }
					}
				  }
				}
				backgroundcolor
				righticon {
				  color
				  image {
					alttext
					imagefile {
					  localFile {
						childImageSharp {
						  gatsbyImageData
						}
					  }
					}
				  }
				}
			  }
			  usertestimonials {
				testimonial {
				  wontext {
					color
					text
				  }
				  testimonialtext {
					color
					text
				  }
				  userdescription {
					location {
					  color
					  fieldGroupName
					  text
					}
					name {
					  color
					  text
					}
					profilepicture {
					  alttext
					  imagefile {
						localFile {
						  childImageSharp {
							gatsbyImageData
						  }
						}
					  }
					}
				  }
				}
			  }
			}
			homepageHerosection {
			  background {
				image {
				  localFile {
					childImageSharp {
					  gatsbyImageData
					}
				  }
				}
				color
			  }
			  imagewithtext {
				description {
				  text
				  color
				}
				image {
				  alttext
				  imagefile {
					localFile {
					  url
					}
				  }
				}
			  }
			  subtitletext {
				color
				text
			  }
			  titletext {
				color
				text
			  }
			  downloadbuttondata {
				buttoniosimage {
				  alttext
				  imagefile {
					localFile {
					  childImageSharp {
						gatsbyImageData
					  }
					}
				  }
				}
				buttonandroidlink
				buttonandroidimage {
				  alttext
				  imagefile {
					localFile {
					  childImageSharp {
						gatsbyImageData
					  }
					}
				  }
				}
				button {
				  buttoncolor
				  buttontext {
					color
					text
				  }
				}
				buttonioslink
				placeholdertext {
				  text
				  color
				}
			  }
			  imageslider {
				scrolltime
				sliders {
				  image {
					alttext
					imagefile {
					  localFile {
						childImageSharp {
						  gatsbyImageData
						}
					  }
					}
				  }
				}
			  }
			}
			homepageSeofields {
			  canonicaltag
			  description
			  fieldGroupName
			  keywords
			  title
			  structuredschemadata {
				structuredjson
			  }
			  favicon {
				localFile {
				  url
				}
			  }
			}
			homepageFaq {
			  background {
				color
				image {
				  localFile {
					childImageSharp {
					  gatsbyImageData
					}
				  }
				}
			  }
			  faqs {
				faq {
				  descriptiontext {
					color
					text
				  }
				  titletext {
					color
					text
				  }
				}
			  }
			  mobilebackground
			  accordianimagedown {
				localFile {
				  childImageSharp {
					gatsbyImageData
				  }
				}
			  }
			  accordianimageup {
				localFile {
				  childImageSharp {
					gatsbyImageData
				  }
				}
			  }
			  titletext {
				color
				text
			  }
			}
			homepageFeaturessection {
			  background {
				image {
				  localFile {
					childImageSharp {
					  gatsbyImageData
					}
				  }
				}
				color
			  }
			  title {
				color
				text
			  }
			  imagearray {
				image {
				  alttext
				  imagefile {
					localFile {
					  childImageSharp {
						gatsbyImageData
					  }
					}
				  }
				}
			  }
			}
			homepageUltimatewinningexperience {
			  background {
				image {
				  localFile {
					childImageSharp {
					  gatsbyImageData
					}
				  }
				}
				color
			  }
			  titletext {
				text
				color
			  }
			  image {
				alttext
				imagefile {
				  localFile {
					childImageSharp {
					  gatsbyImageData
					}
				  }
				}
			  }
			}
			homepagePopupdata {
			  backgroundimage {
				alttext
				imagefile {
				  localFile {
					childImageSharp {
					  gatsbyImageData
					}
				  }
				}
			  }
			  buttondata {
				backgroundcolor
				bordercolor
				buttontext {
				  color
				  text
				}
			  }
			}
			iosdownloadlink
		  }
		}
	  }
	}
  }  
`;
export default IndexPage;
